import { Typography, Paper, Box, CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useLocation } from '@reach/router';
import { unsubscribe, useAppDispatch } from 'app/store/index';
import { useAsyncState } from 'app/hooks';
import { useEffect } from 'react';
import abstractShapesBackground from './abstractShapesBackground.svg';
import { use100vh } from 'react-div-100vh';
import {CobrandLogo, CobrandLogoWithPiP} from 'images/CobrandLogo';

/**
 * A page for users to unsubscribe from emails
 */
const UnsubscribePage = () => {
  const windowLocation = useLocation();
  const userEmail = new URLSearchParams(windowLocation.search).get('email');
  const [unsubscribeState, success, failure, waiting] = useAsyncState();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!userEmail || unsubscribeState.status !== undefined) {
      return;
    }

    waiting();
    dispatch(unsubscribe({ email: userEmail }))
      .unwrap()
      .then(success, failure);
  }, [userEmail, dispatch, unsubscribeState, waiting, success, failure]);
  const height = use100vh();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        height,
        gap: 4,
        backgroundImage: `url(${abstractShapesBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Box
        sx={{
          width: 'min-content',
          flexDirection: 'column',
          alignItems: 'center',
          display: 'flex',
          gap: 6,
        }}>
        <Paper
          sx={{
            bgcolor: blue[50],
            textAlign: 'center',
            px: { xs: 2, sm: 4 },
            py: { xs: 4, sm: 6 },
            width: { xs: '90vw', sm: 500 },
          }}>
          {unsubscribeState.status === 'pending' ? <CircularProgress /> : null}
          {unsubscribeState.status === 'failure' ? (
            <Typography color="error">An error occurred: {JSON.stringify(unsubscribeState.error)}</Typography>
          ) : null}
          {unsubscribeState.status === 'fulfilled' ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography textAlign="center" color="success.main" variant="h6">
                Successfully Unsubscribed
              </Typography>
              <Typography textAlign="center">You can now close this tab</Typography>
            </Box>
          ) : null}
          {unsubscribeState.status === undefined && typeof window !== 'undefined' && userEmail === null ? (
            <Typography sx={{ fontWeight: 'bold' }} color="error">
              Invalid URL
            </Typography>
          ) : null}
        </Paper>
        {/*<CobrandLogo sx={{ width: '80%' }} />*/}
      </Box>
    </Box>
  );
};

export default UnsubscribePage;
